import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { FREE_SHIPPING_COUPON_APPLY_FOR } from './enum'
const { SiteUID } = gbCommonInfo

/**
 * @description 优惠券类型
 * @readonly
 * @enum {number}
 * @property {number} NEW_USER 新人券 - [2, 17, 23, 56, 65]
 */
const couponTypeMap = {
  NEW_USER: [2, 17, 23, 56, 65]
}

/**
   * @description 对各个模块传入的数据进行校正
   * @param {Object} originData 源数据
   * @prop {String} id 优惠券id
   * @prop {String} apply_for
   * apply_for: 1 全部商品
   * apply_for: 2 部分商品
   * apply_for: 3 部分品类
   * apply_for: 4 废弃，产品反馈不存在了
   * apply_for: 5 减最低邮费
   * apply_for: 6 免邮卷
   * apply_for: 7 选品页面
   * apply_for: 8 部分店铺的平台券
   * apply_for: 9 指定运输方式减运费
   * apply_for: 101 ab 价伪装券
   * @prop {String} coupon 券码
   * @prop {String} bind_time 优惠券获取时间
   * @prop {String} start_date 开始日期
   * @prop {String} end_date 结束日期
   * @prop {String} coupon_type_id 优惠券类型，1: 减元券，2/5:折扣券
   * @prop {String} timeStatus 优惠券状态，1: 未到生效时间，2: 即将过期
   * @prop {String} selfStatus
   * @prop {String} times 优惠券可用次数
   * @prop {String} coupon_status 优惠券领取状态, 1、已领取；2、未领取；3、已领完； 4、领取失败
   * @prop {Array} payment_title 支付方式
   * @prop {Array} payment_limit 支付限制
   * @prop {Array} coupon_rule 适用场景
   * @prop {Object} need_price 满足门槛需要金额
   * @prop {Object} real_discount_price 实际抵扣金额
   * @prop {String} filter_rule 是否命中剔除规则
   * @prop {Number} limit 最大抵扣金额
   * @prop {Object} limit_price 最大抵扣金额信息
   * @prop {Object} platformStore 部分店铺的平台信息
   *        @prop {String} type 1-仅自营店铺；2-仅第三方店铺；3-自营+第三方
   * @prop {Number} satisfied_range 满足档位信息
   * 
   * @prop {number} shippingApplyFor 免邮适用场景
   * 
   * MALL 字段
   * @prop {String} coupon_dimension 券类型, 1平台券 2mall券 3店铺券
   * @prop {Object} mall mall信息 @prop {String} mall_code mall编码 @prop {String} mall_name_multi mall名称
   * @prop {Object} store 店铺信息 @prop {String} store_code 店铺编码 @prop {String} store_name_multi 店铺名称
   * 
   * 付费会员字段
   * @prop {String} real_type_id 券类型id  '53': 付费会员券
   * @prop {Number} shein_club_use 是否付费会员券 0不限制 1限制
   * @prop {Number} shipping_discount_type 免邮折扣类型 0全免 1部分抵扣
   * @prop {Array} shipping_list 支持的运输方式
   *    @prop {String} shipping_method_code 运输方式编码
   *    @prop {String} shipping_methd_name 运输方式名称
   *  @prop {String} expandStatus 膨胀优惠券状态 btn展示按钮 seal展示印章 subscript 右上角展示upgrade标签
   * 
   * 列表品类投放券（因目前后端未适配，读取本地配置文件，故单独适配，后期使用后端适配字段）
  
   * @prop {Boolean} isCateCoupon
   * 
   * //  * todo这里直接定义需要的超省卡字段， 通过这里进行数据整合
   * @prop {Boolean} isSearchCoupon 超省卡类型
   * 
   * @prop {Boolean} isSearchCoupon 是否购买过
   * 
   * @prop {Boolean} isSearchCoupon 超级省卡状态
   * 
   * @prop {Boolean} isSearchCoupon 是否购买过
   */
export const souceFormat = (originData) => {
  let [_item, item] = [{}, originData]
  item.keyId = _item.keyId = new Date().getTime()
  _item.handle_flag = item.handle_flag ? ++item.handle_flag : 1
  // 已处理过一次的，不再重复处理
  if(_item.handle_flag > 1) return item 
  _item.coupon = item?.coupon || item?.couponCode || item?.couponcode || item?.coupon_code || ''
  _item.id = item?.id || item?.couponId || _item.coupon || ''
  _item.apply_for = (item?.apply_for || item?.applyFor || '') + ''
  _item.platform_store = item?.platformStore || item?.platform_store || {}
  _item.start_date = formatDate(item?.startTimestamp || item?.start_date || item?.startTime || item?.start_time || item?.startDate || '')
  _item.end_date = formatDate(item?.endTimestamp || item?.end_date || item?.endTime || item?.end_time || item?.endDate || '')
  _item.no_trans_start_date = item?.no_trans_start_date || ''
  _item.no_trans_end_date = item?.no_trans_end_date || ''
  _item.bind_time = item?.bind_time || ''
  _item.coupon_type_id = item?.rule_dimension || item?.ruleDimension || item?.coupon_type_id || item?.couponTypeId || item?.exportType || ''
  _item.timeStatus = item?.timeStatus || ''
  _item.selfStatus = item?.selfStatus || ''
  _item.times = item?.times || item?.couponTimes || item?.singleAvailableTimes || ''
  _item.category_id = item.category_id || item.categoryId || ''
  _item.category_name = item?.category_name || item?.category_name_list || []
  _item.scId = item?.scId || ''
  _item.coupon_status = item?.coupon_status || item?.couponStatus || item?.bind_status || ''
  _item.payment_title = item?.payment_title || item?.paymentTitle || []
  _item.payment_limit = item?.payment_limit || item?.paymentLimit || []
  _item.need_price = item?.needPrice || {}
  _item.real_discount_price = item?.realDiscountPrice || {}
  _item.filter_rule = +(item?.filter_rule || item?.filterRule || '0')
  _item.limit = +item?.limit || 0
  _item.limit_price = item?.limit_price || {}
  _item.satisfied_range = item?.satisfied_range || item?.satisfiedRange || 0
  _item.real_type_id = item?.real_type_id || item?.realTypeId || item?.type_id || ''
  _item.shein_club_use = item?.shein_club_use || item?.sheinClubUse || 0
  _item.shipping_discount_type =
    String(item?.shipping_discount_type)
  if (_item?.shipping_discount_type == 'undefined') {
    _item.shipping_discount_type = String(item?.shippingDiscountType)
  }
  _item.shipping_list = item?.shipping_list || item?.shippingList || []
  _item.expandStatus = item?.expandStatus || ''
  _item.is_expand = item?.is_expand
  _item.is_best = !!Number(item?.is_best)
  if (item?.is_bind == 'undefined') {
    _item.is_bind = item?.isBind
  } else {
    _item.is_bind = item?.is_bind
  }
  _item.isCateCoupon = item?.isCateCoupon || false
  _item.is_estimated_best_coupon = item?.is_estimated_best_coupon || false
  _item.is_stacked_best_coupon = item?.is_stacked_best_coupon || false
  _item.is_new_user = couponTypeMap.NEW_USER?.includes?.(+item?.type_id)
  _item.list_type = item?.list_type || ''
  _item.expandedCouponInfo = item?.expandedCouponInfo || {}

  const rules = item?.other_coupon_rule || item?.rule || item?.coupon_rule || item?.coupon_rules || item?.couponRule || []
  _item.coupon_rule = []

  _item.shippingApplyFor = item?.shipping_apply_for  || item?.shippingApplyFor || -1

  _item.countdownTime = item?.countdownTime || ''
  // 付费会员/超省卡会费券适用范围信息
  _item.rights_info = item?.rights_info || {}
  _item.reason = item.reason
  // 卡 bin 优惠券的卡信息
  _item.card = item.card || {}

  if (rules.length) {
    // 普通优惠券
    rules.forEach((rule, index) => {
      const ruleObj = { coupon_gift_id: '', value: {}, min: {}, max: {} }
      ruleObj.coupon_gift_id = rule?.coupon_gift_id || ''
      ruleObj.satisfied_max_range = rule?.satisfied_max_range || ''
      if (rule.value) {
        ruleObj.value.amount = rule.value
        ruleObj.value.amountWithSymbol = rule.value
      }
      if (rule.price) {
        ruleObj.value.amount = rule.price.price
        ruleObj.value.amountWithSymbol = rule.price.priceSymbol
      }
      if (rule.min_order) {
        ruleObj.min.amount = rule.min_order.price
        ruleObj.min.amountWithSymbol = rule.min_order.priceSymbol
      }
      if (rule.max_order) {
        ruleObj.max.amount = rule.max_order.price
        ruleObj.max.amountWithSymbol = rule.max_order.priceSymbol
      }
      if (rule.valuePrice) {
        ruleObj.value.amount = rule.valuePrice.amount
        ruleObj.value.amountWithSymbol = rule.valuePrice.amountWithSymbol
      }
      if (rule.minPrice) {
        ruleObj.min.amount = rule.minPrice.amount
        ruleObj.min.amountWithSymbol = rule.minPrice.amountWithSymbol
      }
      if (rule.maxPrice) {
        ruleObj.max.amount = rule.maxPrice.amount
        ruleObj.max.amountWithSymbol = rule.maxPrice.amountWithSymbol
      }
      if (rule.value?.amount) {
        ruleObj.value.amount = rule.value.amount
        ruleObj.value.amountWithSymbol = rule.value.amountWithSymbol
      }
      if (rule.min?.amount) {
        ruleObj.min.amount = rule.min.amount
        ruleObj.min.amountWithSymbol = rule.min.amountWithSymbol
      }
      if (rule.max?.amount) {
        ruleObj.max.amount = rule.max.amount
        ruleObj.max.amountWithSymbol = rule.max.amountWithSymbol
      }
      if (rule.minAmount?.amount) {
        ruleObj.min.amount = rule.minAmount.amount
        ruleObj.min.amountWithSymbol = rule.minAmount.amountWithSymbol
      }
      if (rule.maxAmount?.amount) {
        ruleObj.max.amount = rule.maxAmount.amount
        ruleObj.max.amountWithSymbol = rule.maxAmount.amountWithSymbol
      }
      if (rule.valueAmount?.amount) {
        ruleObj.value.amount = rule.valueAmount.amount
        ruleObj.value.amountWithSymbol = rule.valueAmount.amountWithSymbol
      }
      if (rule.value_amount?.amount) {
        ruleObj.value.amount = rule.coupon_gift_id == '1' ? rule.value_amount?.amount : ((1 - rule.value) * 100).toFixed(0)
        ruleObj.value.amountWithSymbol = rule.value_amount?.amountWithSymbol
      }
      _item.coupon_rule[index] = {
        ...rule,
        ...ruleObj
      }
    })
  }

  if (_item.coupon_rule.length > 1) {
    _item.coupon_rule.sort(createComparionFun())
  }

  // 巴西mall字段
  _item.coupon_dimension = String(item?.coupon_dimension || item?.couponDimension || '')
  // mall信息
  if (item?.mall) {
    _item.mall = {
      mall_code: item?.mall?.mall_code || item?.mall?.mallCode || '',
      mall_name_multi: item?.mall?.mall_name_multi || item?.mall?.mallNameMulti || ''
    }
  }
  // 店铺信息
  if (item?.store) {
    _item.store = {
      store_code: item?.store?.store_code || item?.store?.storeCode || '',
      store_name_multi: item?.store?.store_name_multi || item?.store?.storeNameMulti || ''
    }
  }

  // 第三方店铺信息
  if (_item?.platform_store) {
    const storesName = _item?.platform_store?.third_store_total?.third_stores?.map(item => {
      return item?.store_name_multi
    })
    _item.thirdStoreInfo = {
      storeNum: _item?.platform_store?.third_store_total?.store_num || 0,
      storesName: storesName?.join(','),
    }
  }

  return _item
}

// 规则排序
const createComparionFun = () => {
  return (object1, object2) => {
    const value1 = +object1?.value?.amount || 0
    const value2 = +object2?.value?.amount || 0
    if (value1 > value2) {
      return 1
    } else if (value1 < value2) {
      return -1
    } else {
      return 0
    }
  }
}

// 日期处理
function formatDate (date) {
  return Number(date) == date
    ? date
    : (new Date(date.replace(/-/gi, '/')).getTime()) / 1000 // .replace(/-/gi, '/') 兼容safari转换
}

// 膨胀优惠卷
export const getCodeExpandAbt = async () => {
  let flagExpansion = {}
  const newPosKeys = 'CodeExpand'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  if (abtInfo?.CodeExpand?.param) {
    flagExpansion = abtInfo?.CodeExpand?.param
  }
  const abtest =
    // eslint-disable-next-line @shein-aidc/abt/abt
    (await abtservice.getUserAbtResultForAnalysis({ abtInfo }).sa) || ''
  return {
    flagExpansion,
    abtestExpansion: abtest,
  }
}

// 订单返券abt
export const getReturnInformAbt = async key => {
  const newPosKeys = 'ReturnInform'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  return abtInfo?.[newPosKeys].param?.[key] == 'on'
}

// 手势动画abt
export const getReturnGestureAbt = async () => {
  const newPosKeys = 'CouponBagUITest'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  const info = abtInfo?.CouponBagUITest?.param?.CouponBagLittleHand
  return info == 'A' ? true : false
}

// 优惠券法务文案abt
export const getPostAmountAbt = async () => {
  const posKeys = 'PostAmount'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ posKeys })
  if (abtInfo?.[posKeys]?.param === 'ShowPostAmount') {
    return true
  }
  return false
}

// 新凑单abt
export const getCouponaddallAbt = async () => {
  const newPosKeys = 'Couponaddall'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  return abtInfo
}

// 展示倒计时abt
export const getCouponPatternAbt = async () => {
  let flag = false
  const newPosKeys = 'CouponPattern'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  if (abtInfo?.CouponPattern?.param?.CouponCountdown == 'show') {
    flag = true
  }

  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtest = await abtservice.getUserAbtResultForAnalysis({ abtInfo }).sa || ''
  return {
    flag,
    abtest: abtest
  }
}

// 展示 付费会员 Filter abt sheinclubme
export const sheinClubFilterABT = async () => {
  const newPosKeys = 'sheinclubme'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  return abtInfo
}

// 展示超省卡 Filter abt SaverSwitch
export const sheinSaverFilterABT = async () => {
  const newPosKeys = 'SaverSwitch'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  return abtInfo
}

// 指定运输方式减运费券可叠加提示语 abt
export const shippingCouponStackABT = async () => {
  const newPosKeys = 'shippingcouponstack'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  return abtInfo?.shippingcouponstack?.param?.shippingcouponstackswitch == 'on'
}

/**
 * 优惠券列表字段格式化
 * mall: {} MALL信息; store: {} 店铺信息; ...item 优惠券信息
 * @origin 源数据格式为 { mall: { store: { coupons: [] } } }
 * @format 格式化之后 { coupons: [ ...item, mall: {}, store: {} ] }
 * @param {*} info 
 */
export const listFormat = (info) => {
  let list = []
  const mall = { ...info?.mall_info || {} }
  const store = { ...info?.mall_info?.store_info || {} }
  const coupons = info?.mall_info?.store_info?.coupon_info || []
  list = coupons.map(item => {
    return Object.assign(item, { mall, store })
  })
  return list
}

// 绑定优惠券接口
export const bingCouponApi = async (data) => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/coupon/bindCouponsStore/update',
      data
    })
    return res
  } catch (error) {
    return error
  }
}

// 命中到手价实验，新的绑定优惠券接口
export const estimatedBindCouponApi = async (data) => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/productInfo/bindCoupon/post', // 新接口
      data
    })
    return res
  } catch (error) {
    return error
  }
}

/**
 * 获取文案行数
 * 容器高度 / 行高
 * @param {*} el 
 * @returns 行数
 */
export const getTextLline = (el) => {
  return Math.round(el.height() / parseFloat(el.css('line-height').replace('px', '')))
}

// 膨胀数据处理
export const filterExpandDataDeleted = (data = [], user = {}) => {
  const member_id = user?.member_id || ''
  if (!data.length || !member_id) return data
  let [cacheData, abtInfo, afterCodes, defaultCodes] = [null, {}, [], []]
  try {
    // 缓存的膨胀信息
    cacheData = JSON.parse(window.localStorage.getItem('coupon_expand_cache'))
    // 缓存的abt信息
    // CodeExpandShow: 原abt里面的膨胀券信息: us00&us01_us02&us03
    // CodeExpandDefault: 原abt里面的原本优惠券信息: us01_us03
    abtInfo = window.localStorage.getItem('coupon_expand_abt_info') || {}
    if (abtInfo) {
      abtInfo = JSON.parse(abtInfo)
    }
  } catch (error) {
    cacheData = null
    abtInfo = {}
  }
  if (!cacheData) return data
  // if (abtInfo?.CodeExpandShow) {
  //   // 过滤abt控制的膨胀后的数据
  //   abtInfo.CodeExpandShow.split('_')?.forEach(_ => afterCodes.push(_.split('&')?.[1] || ''))
  // }
  afterCodes = JSON.parse(window.localStorage.getItem('afterCodes'))
  // 当前用户缓存数据
  console.log(cacheData, 'filterExpandData cacheData=====')
  const expandInfo = cacheData?.[`${member_id}_${SiteUID}`] || {}
  // 被BAN掉的券
  // 这里使用后端接口返回的逻辑

  // if (abtInfo?.CodeExpandDefault) {
  //   defaultCodes = [...abtInfo.CodeExpandDefault.split('_')]  // [us01,us03]
  // }
  defaultCodes = JSON.parse(window.localStorage.getItem('beforeExpandCouponCodes')) // beforeExpandCouponCodes
  // 此方法作用，1标记膨胀后的券，增加expandStatus状态，2.点击膨胀后的券不显示（只显示原有的）3.
  return data.filter(val => {
    // 打上标签
    if (Object.values(expandInfo)?.includes(val.coupon)) {
      val.expandStatus = 'subscript'
    }
    // 是否膨胀过
    const expandFlag = expandInfo?.[val.coupon] || ''
    if (expandFlag) {
      // 膨胀过的需要过滤掉
      return false
    } else {
      // 膨胀之后的数据需要展示
      if (Object.values(expandInfo)?.includes(val.coupon)) {
        return true
      } else {
        //afterCodes = 01 03
        // 未膨胀的数据，需要过滤掉膨胀后的数据 和 被BAN掉的券
        return !afterCodes.includes(val.coupon) && !defaultCodes.some(_ => _ == val.coupon)
      }
    }
  })
}


// 膨胀数据处理
export const filterExpandData = (data = [], user = {}) => {
  const member_id = user?.member_id || ''
  if (!data.length || !member_id) return data
  let [cacheData, abtInfo, afterCodes, defaultCodes] = [null, {}, [], []]
  try {
    // 缓存的膨胀信息
    cacheData = JSON.parse(window.localStorage.getItem('coupon_expand_cache'))
    // 缓存的abt信息
    abtInfo = window.localStorage.getItem('coupon_expand_abt_info') || {}
    if (abtInfo) {
      abtInfo = JSON.parse(abtInfo)
    }
  } catch (error) {
    cacheData = null
    abtInfo = {}
  }
  if (!cacheData) return data
  if (abtInfo?.CodeExpandShow) {
    // 过滤abt控制的膨胀后的数据
    abtInfo.CodeExpandShow.split('_')?.forEach(_ => afterCodes.push(_.split('&')?.[1] || ''))
  }
  // 当前用户缓存数据
  const expandInfo = cacheData?.[`${member_id}_${SiteUID}`] || {}
  // 被BAN掉的券
  if (abtInfo?.CodeExpandDefault) {
    defaultCodes = [...abtInfo.CodeExpandDefault.split('_')]
  }
  return data.filter(val => {
    // 打上标签
    if (Object.values(expandInfo)?.includes(val.coupon)) {
      val.expandStatus = 'subscript'
    }
    // 是否膨胀过
    const expandFlag = expandInfo?.[val.coupon] || ''
    if (expandFlag) {
      // 膨胀过的需要过滤掉
      return false
    } else {
      // 膨胀之后的数据需要展示
      if (Object.values(expandInfo)?.includes(val.coupon)) {
        return true
      } else {
        // 未膨胀的数据，需要过滤掉膨胀后的数据 和 被BAN掉的券
        return !afterCodes.includes(val.coupon) && !defaultCodes.some(_ => _ == val.coupon)
      }
    }
  })
}


// 判断是否新客
export const getCurrentUserStatus = async () => {
  const newPosKeys = 'CouponBagUITest'
  // eslint-disable-next-line @shein-aidc/abt/abt
  const couponAbtInfo = await abtservice.getUserAbtResult({ newPosKeys })
  return couponAbtInfo
}


// 优惠券标识tag map
export const couponTagHandleMap = {
  // 新人专享券 满足条件：1.新人券（56） 2.abt
  NEW_USER_GIFT: {
    check: ({ item, abtNewUserGifts = false }) =>
      item.is_new_user && abtNewUserGifts,
    langKey: 'SHEIN_KEY_PWA_31231', // New User Gifts
    sort: 0
  },
  // 最优券
  BEST_COUPON: {
    check: ({ item }) => !!item.is_estimated_best_coupon,
    langKey: 'SHEIN_KEY_PWA_29477', // Best Coupon
    sort: 1
  },
  // 最优叠加券
  BEST_COUPON_STACK: {
    check: ({ item }) => !!item.is_stacked_best_coupon,
    langKey: 'SHEIN_KEY_PWA_29125', // Best Coupn for stacking
    sort: 1
  },
  // ab 价格
  AB_PRICE: {
    check: ({ item }) => item.apply_for == 101,
    langKey: 'SHEIN_KEY_PWA_26357',
    sort: 2
  },
}

// 获取券包 abt
export const getCouponAllAbt = async key => {
  const newPosKeys = key
  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys })

  return abtInfo
}

// 根据劵类型设置主题色
export const couponThemeConfig =  {
  // 默认主题
  defaultTheme: {
    '--themeColor': '#FA6338',
    '--bgColor': '#FFF6F3',
    '--borderColor': '#FFE2CF',
  },
  // 免邮皮肤
  greenTheme: {
    '--themeColor': '#169E00',
    '--bgColor': '#F3FAF6',
    '--borderColor': '#CBE2C7',
  },
  // 超省卡的满折券和满减券皮肤
  superSaveTheme: {
    '--themeColor': '#F82854',
    '--bgColor': '#FFF4F7',
    '--borderColor': '#FEE3ED',
  }
}

// 根据劵类型设置主题色（下单页&个人中心通用）
export const getThemeConfig = (coupon) => {
  // 超省卡的满折券和满减券，需要渲染成玫红色
  if(!['5', '6', '9'].includes(coupon.apply_for) && coupon.real_type_id == '55'){ 
    return couponThemeConfig.superSaveTheme
  }
  if (['5', '6', '9'].includes(coupon.apply_for)) {
    return {
      ...couponThemeConfig.greenTheme,
      '--labelTextColor': '#2D68A8',
      '--labelBgColor': '#EFF3F8',
      '--labelBorderColor': '51, 126, 255',
    }
  }
  return null
}

// 是否免邮券
export const isFreeShippingCoupon = (coupon) => {
  return FREE_SHIPPING_COUPON_APPLY_FOR.includes(coupon.apply_for)
}

// 批量获取店铺是否配置优惠券
export const validateCouponPayment = async (data) => {
  try {
    const res = await schttp({
      method: 'POST',
      url: '/api/coupon/validateCouponPayment/get',
      data
    })
    return res
  } catch (error) {
    return error
  }
}
